/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useContext, useEffect } from 'react'
import { useLanguageInfo } from '../../context/language'
import { useAppServices } from '../../hook/services'
import { useParams } from 'react-router-dom'
import { isDisabled } from './Sidebar'

const TABS = {
  DOC: 'doc',
  FAQ: 'faq',
  SETTING: 'setting',
  EDITOR: 'editor',
}

const initDocContextPropsState = {
  activeDoc: {},
  activeCat: {},
  relatedDocs: [],
  activeTab: TABS.DOC,
  data: {},
  formState: undefined,
  setActiveDoc: () => {},
  setActiveCat: () => {},
  setRelatedDocs: () => {},
  setActiveTab: () => {},
  setData: () => {},
  getLanguage: () => {},
  setFormState: () => {},
  onLoad: () => {},
}

const DocContext = createContext(initDocContextPropsState)

const useDoc = () => {
  return useContext(DocContext)
}

const DocProvider = ({ children }: any) => {
  const [activeDoc, setActiveDoc] = useState({})
  const [activeCat, setActiveCat] = useState({})
  const [relatedDocs, setRelatedDocs] = useState([])
  const [activeTab, setActiveTab] = useState(TABS.DOC)
  const [language] = useLanguageInfo()
  const { company_id, app_id } = useParams()
  const [data, setData] = useState({})
  const [loader, setLoader] = useState(true)
  const [formState, setFormState] = useState()
  const AppService = useAppServices()

  const getLanguage = (data, key) => {
    if (language == 'english') return data[key]

    const meaningExist = data?.languages?.find(
      (item) => item.languageId == language
    )
    if (meaningExist && meaningExist[key]) return meaningExist[key]

    return data[key]
  }

  const getDocsData = async (cat_id) => {
    const { response } = await AppService.docs.get({
      query: `category=${cat_id}`,
    })
    if (response?.data?.length) {
      setActiveDoc(response.data[0])
    }
  }

  const getData = async () => {
    setLoader(true)
    const { response } = await AppService.docs_category.filter({
      query: `company_id=${company_id}&app_id=${app_id}`,
    })
    if (response) {
      setData(response.data)
      const firstPublishCategory = response.data?.category?.find(
        (category) => !isDisabled(category)
      )
      await getDocsData(firstPublishCategory?._id)
      setActiveCat(firstPublishCategory)
    }
    setLoader(false)
  }

  const onLoad = () => {
    getData()
  }
  useEffect(onLoad, [])

  return (
    <DocContext.Provider
      value={{
        activeDoc,
        setActiveDoc,
        activeCat,
        setActiveCat,
        relatedDocs,
        setRelatedDocs,
        activeTab,
        setActiveTab,
        getLanguage,
        data,
        setData,
        formState,
        setFormState,
        onLoad,
      }}
    >
      {loader ? (
        <div className='w-full h-screen flex justify-center items-center flex-col bg-[#1E1E1E] text-gray-200'>
          <div>Loading...</div>
        </div>
      ) : (
        children
      )}
    </DocContext.Provider>
  )
}

export { DocProvider, useDoc, TABS }
