import { useFormikContext } from 'formik'
// import ClickOutSide from 'hooks/click-outside'
// import Paint from 'icon/paint'
import {Paint} from '../Doc'
import React, { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import ClickOutSide from '../../../hook/click-outside'

function ColorPicker({ name, label, required }:any) {
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const formik = useFormikContext()
  const getFieldValue = (name) => {
    return name.split('.').reduce((a, item) => {
      return a ? a[item] : formik.values[item]
    }, '')
  }
  return (
    <ClickOutSide onClickOutside={() => setOpenColorPicker(false)}>
      <div className='grow flex flex-col gap-[7px]'>
        <div className='text-[#304861] text-[15px] md:text-[13px] font-[500] heading-[20px]'>
          {required ? label + ' *' : label}
        </div>
        <div
          onClick={() => setOpenColorPicker(!openColorPicker)}
          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full  min-h-[55px] md:min-h-[40px] px-[11px] flex items-center'
        >
          <div className='grow h-full flex items-center'>
            <div className=' text-[16px] placeholder-gray-300 md:text-[14px] w-full rounded-regular leading-regular flex items-center justify-between h-full'>
              <div className='flex items-center'>
                <span
                  className='w-[18px] h-[18px]  mr-[8px] flex my-auto rounded-sm'
                  style={{
                    background: getFieldValue(name),
                  }}
                ></span>
                {getFieldValue(name)}
              </div>
              <Paint />
            </div>
          </div>
        </div>
      </div>
      {openColorPicker && (
        <div className='absolute mt-1 z-20'>
          <div className='bg-white border w-min border-slate-300 drop-shadow-lg flex flex-col items-center justify-center p-4 rounded-md'>
            <HexColorPicker
              color={getFieldValue(name)}
              onChange={(value) => formik.setFieldValue(name, value)}
              className='mb-3'
            />
            <div className='border border-slate-300 flex items-stretch rounded-md'>
              <div className='bg-slate-100 flex items-center px-3 rounded-l-md text-slate-500'>
                #
              </div>
              <input
                className='border-l border-slate-300 block rounded-r-md placeholder-gray-300 p-1 text-center w-24'
                spellCheck='false'
                {...formik.getFieldProps(name)}
              />
            </div>
          </div>
        </div>
      )}
    </ClickOutSide>
  )
}

export default ColorPicker
