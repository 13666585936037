import React, { useEffect, useRef } from 'react'

function ClickOutSide({ onClickOutside, children, ...props }:any) {
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [onClickOutside])

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  )
}

export default ClickOutSide
