/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppServices } from '../hook/services'

const initAgencyContextPropsState = {
  agency: {},
  setAgency: () => {},
}

const AgencyContext = createContext(initAgencyContextPropsState)

const useAgency = () => {
  return useContext(AgencyContext)
}

const AgencyProvider = ({ children }: any) => {
  const [agency, setAgency] = useState({})
  const [loader, setLoader] = useState(false)
  const { app_id, company_id } = useParams()
  const AppService = useAppServices()

  const getAgency = async () => {
    const { response } = await AppService.agency.getProfile({
      query: `company_id=${company_id}&app_id=${app_id}`,
    })
    if (response) {
      setAgency(response.data)
      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  const onLoad = () => {
    getAgency()
  }
  useEffect(onLoad, [])

  return (
    <AgencyContext.Provider
      value={{
        agency,
        setAgency,
      }}
    >
      {loader ? (
        <div className='w-full h-screen flex justify-center items-center flex-col bg-[#1E1E1E] text-gray-200'>
          <div>Loading...</div>
        </div>
      ) : (
        children
      )}
    </AgencyContext.Provider>
  )
}

export { AgencyProvider, useAgency }
