import React, { useEffect, useState } from 'react'
import Input from '../comp/Input'
import { Form, Formik, useFormikContext } from 'formik'
import Button from '../comp/Button'
import { useAppServices, useUploadImage } from '../../../hook/services'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { DeleteIcon } from '../Doc'
import ColorPicker from '../comp/color-picker'
import { useTheme } from '../../../context/theme.context'
import { useAgency } from '../../../context/agency.context'

function GeneraInfo() {
  const { values, setFieldValue } = useFormikContext()
  console.log(values.generalInfo,"valuesgg")

  return (
    <>
      {[
        {
          id: 'companyName',
          label: 'Company Name',
          placeholder: 'Enter your company name',
        },
        {
          id: 'companyAddress',
          label: 'Address',
          placeholder: 'Enter your address',
        },
        {
          id: 'companyPhone',
          label: 'Phone',
          placeholder: 'Enter your phone',
        },
        {
          id: 'companyWebsite',
          label: 'Website',
          placeholder: 'Enter your website',
        },
        {
          id: 'poweredBy',
          label: 'Powered By',
          placeholder: 'Enter your powered by',
        },
      ].map((field) => (
        <div className='mb-6' key={field.id}>
          <label
            htmlFor={field.id}
            className='block text-white font-semibold mb-2'
          >
            {field.label}
          </label>
          <Input
              // onChangeComplete={(value) => setFieldValue('generalInfo.'+field.id, value)}
              onChange={(e) => setFieldValue('generalInfo.' + field.id, e.target.value)}
              value={values.generalInfo[field.id] }
              name={'generalInfo.' + field.id}
              placeholder={field.placeholder}
            />

        </div>
      ))}
    </>
  )
}

function SocialLinks() {
  const { values, setFieldValue } = useFormikContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [links, setLinks] = useState([])
  const [groupName, setGroupName] = useState('')

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const addLinkField = () => {
    setLinks([...links, { id: Date.now(), name: '', url: '' }])
  }

  const removeLinkField = (id) => {
    setLinks(links.filter((link) => link.id !== id))
  }

  const handleInputChange = (id, field, value) => {
    setLinks(
      links.map((link) => (link.id === id ? { ...link, [field]: value } : link))
    )
  }

  const saveGroup = () => {
    if (groupName.trim()) {
      setFieldValue('urlGroups', [
        ...values.urlGroups,
        {
          id: Date.now(),
          groupName,
          links,
        },
      ])
      setGroupName('')
      setLinks([])
      closeModal()
    }
  }

  console.log('GTOUP', values.urlGroups)

  return (
    <>
      <div className='text-sm text-white p-4 rounded-md shadow-sm mt-4 text-end'>
        {/* <Input type="button" onClick={openModal} className="w-32 py-2 px-4"  value="Add Group URL" /> */}
        <Button onClick={openModal} className='w-36'>
          Add Group URL
        </Button>
      </div>

      {/* Render saved groups */}
      {values?.urlGroups.map((group) => (
        <div key={group.id} className='mt-4 p-4 bg-gray-800 rounded-md'>
          {/* Group Heading */}
          <h2 className='text-white font-bold text-lg mb-4'>
            {group.groupName}
            {console.log("group",group)}
          </h2>

          {/* Group Links */}
          {group?.links?.map((link) => (
            <div key={link.id} className='mb-4'>
              <label className='block text-white font-semibold mb-2'>
                {link.name}
              </label>
              <Input
                readOnly
                value={link.url}
              />
            </div>
          ))}
        </div>
      ))}

      {/* Modal */}
      {isModalOpen && (
        <div
          className='fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 backdrop-blur-lg z-50'
          onClick={(e) => e.target === e.currentTarget && closeModal()}
        >
          <div className='bg-gray-800 p-8 rounded-xl shadow-xl w-[80%] max-w-3xl'>
            <h2 className='text-white font-bold text-lg mb-4'>Group Name</h2>

            <Input
              type='text'
              placeholder='Enter group name'
              onChange={(e) => setGroupName(e.target.value)}
              value={values.groupName}
              setFieldValue={values.groupName}
              className='mb-4 rounded-md bg-gray-700 text-white border border-gray-600'
            />

            <Button
              className='bg-gray-600 w-32 text-white px-4 py-2 rounded-md mb-4'
              onClick={addLinkField}
            >
              Add URL
            </Button>

            {links.map((link) => (
              <div key={link.id} className='relative mb-4 flex gap-4'>
                {/* URL Label */}
                <div className='flex-1'>
                  <label className='text-white text-sm block mb-1'>
                    Url label
                  </label>
                  <Input
                    type='text'
                    placeholder='Enter label'
                    onChange={(e) =>
                      handleInputChange(link.id, 'name', e.target.value)
                    }
                    setFieldValue={link.name}
                    value={link.name}
                    className='w-full rounded-md bg-gray-700 text-white border border-gray-600'
                  />
                </div>

                {/* URL Link */}
                <div className='flex-1'>
                  <label className='text-white text-sm block mb-1'>
                    Url link
                  </label>
                  <Input
                    type='text'
                    placeholder='https://example.com'
                    onChange={(e) =>
                      handleInputChange(link.id, 'url', e.target.value)
                    }
                    value={link.url}
                    setFieldValue={link.url}
                    className='w-full rounded-md bg-gray-700 text-white border border-gray-600'
                  />
                </div>

                {/* Delete Button - Positioned Top Right */}
                <Button
                  className='absolute -top-3 -right-2 p-1 rounded-full text-white hover:bg-gray-700 w-8 h-8  flex items-center justify-center'
                  onClick={() => removeLinkField(link.id)}
                >
                  <DeleteIcon className='w-4 h-4' />
                </Button>
              </div>
            ))}

            <div className='flex justify-end gap-4 mt-6'>
              <Button
                onClick={saveGroup}
                className='bg-gray-600 text-white w-32 px-4 py-2 rounded-md mb-4'
              >
                Save Group
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function Branding() {
  const { values, setFieldValue } = useFormikContext()

  const colorConfig = [
    { label: 'Page Color', colorKey: 'pageBackground', group: 'Page Color' },
    {
      label: 'Button Background',
      colorKey: 'buttonBgColor',
      group: 'Button Color',
    },
    {
      label: 'Button Hover',
      colorKey: 'buttonHoverColor',
      group: 'Button Color',
    },
    {
      label: 'Button Text',
      colorKey: 'buttonTextColor',
      group: 'Button Color',
    },
    { label: 'Text Active', colorKey: 'textColor', group: 'Text Color' },
    {
      label: 'Text Secondary',
      colorKey: 'textSecondaryColor',
      group: 'Text Color',
    },
    {
      label: 'Text Field Background',
      colorKey: 'textFieldBgColor',
      group: 'Text Color',
    },
    {
      label: 'Text Field Text',
      colorKey: 'textFieldTextColor',
      group: 'Text Color',
    },
    {
      label: 'Text Field Border',
      colorKey: 'textFieldBorderColor',
      group: 'Text Color',
    },
    {
      label: 'Card Background',
      colorKey: 'cardBgColor',
      group: 'Card Color',
    },
  ]

  return (
    <div className='max-w-2xl mx-auto p-6 space-y-6'>
      <h2 className='text-2xl font-bold text-white'>Branding Colors</h2>

      {['Page Color', 'Button Color', 'Text Color','Card Color'].map((group) => (
        <div key={group} className='space-y-4'>
          <h3 className='text-lg font-semibold text-white'>{group}</h3>

          {colorConfig
            .filter((item) => item.group === group)
            .map(({ label, colorKey }) => (
              <div
                key={colorKey}
                className='flex items-center justify-between gap-4'
              >
                <span className='text-sm text-white'>{label}</span>

                <div className='flex items-center gap-2'>
                  <div className='w-[120px] h-12'>
                    {/* Removed unnecessary text-red-600 */}
                   {console.log("colorrr",values.branding[colorKey])}
                    <ColorPicker
                      color={values.branding[colorKey]}
                      onChangeComplete={(color) =>
                        setFieldValue('branding.' + colorKey, color)
                      }
                      className='w-full h-full rounded cursor-pointer'
                      name={'branding.' + colorKey}
                      value={values.branding[colorKey]}
                    />
                  </div>
                </div>
              </div>
            ))}

          <hr className='border-gray-600 my-4' />
        </div>
      ))}
    </div>
  )
}

function Domain() {
  const { values, setFieldValue } = useFormikContext()
  return (
    <>
      <div className='mb-6'>
        <label htmlFor='Domain' className='block text-white font-semibold mb-2'>
          Domain
        </label>
        <Input
          placeholder={'abc.domain'}
          onChange={(e) => setFieldValue('domain', e.target.value)}
          value={values.domain}
        />
        {console.log(values.domain,"valuesdomain")}
        <div className='text-sm text-white bg-gray-700 p-4 rounded-md shadow-sm mt-4'>
          <h3 className='font-semibold text-gray-300 mb-2'>
            Connect White Label Domain
          </h3>
          <p className='mb-2 text-gray-300'>
            Connect your own domain to the system (without <code>https://</code>
            ) e.g., <code>knowledebase.yourdomain.com</code>
          </p>
          <p className='mb-2 text-gray-300'>
            You can use any domain you own or create a sub-domain like{' '}
            <code>knowledebase.yourdomain.com</code> to get white-labeled access
            to the platform.
          </p>
          <ol className='list-decimal list-inside mb-2 text-gray-300'>
            <li>
              Refer to your domain registrar's control panel and choose the
              desired domain zone.
            </li>
            <li>
              Create a record of <strong>ANAME</strong> type, enter your
              domain/subdomain name, and point it to <code>161.35.33.153</code>.
            </li>
          </ol>
          <p className=' text-gray-300'>We will do the rest!</p>
        </div>
      </div>
    </>
  )
}

function ImageLoader(){
  const uploadImage = useUploadImage()
  const {values, setFieldValue} = useFormikContext()

  
  const logoUrl = 
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKMAAACUCAMAAADIzWmnAAAAMFBMVEXk5ueutLfo6uu7wMOrsbTf4uPS1dfFycunrrHa3d64vcDLz9G+w8Wxt7q1ur3P0tQg9C1iAAAD30lEQVR4nO2b646sIAyApXJV0Pd/20XHmTMXnYEWiyfh+7XZbLJfCuXW2nWNRqPRaDQajUaj0Wg0LgXA509XIlo5PRrv+94bM2p3PU1wZgpCPhBhMupKmqBGYaV4Q9owq4tYgjLhQ/CGncbuEpajODBcgyl0dUlwwxfDNZa9q2sJ43fBNZShbijNjyBulmNFRZ+kGMfb1Iok9ImKMZK1JNMVYyR9DUNIHehNcuSPJMw2RzHCnt3gMg2FCIrZsfu1dH8ie95AQtrC+CbJO9oKoSjExDnamTn9CCTnfqMxhguMjrgwLoHkm5FIxbj+cBmiknqDK7UhoBUl07aN2GL+wbT8UIY6ruMsjuisXh15DpIKPx0jgcXREcIYz5EsjtkHx1dHx6BISpk4IWeGQGbctHYdObZDmCiKQngOR1JaC8FxGgfSUAsxNMfmWNKRmNcsOTOQFFkOFcg74cOR5Uoz0hxZbguadqZgOYhTrgoxjjxnXFLScCw9HfGuwPMKADMhjJLjiBtRBMeB6emMsIqz1RfA4Vcfvroc+pjLlNUL2K2G5VK4oXBnHznwKXaADCTTY88miZmRkuNK+OSoMbUP7iJS/mtFhYphdqGLv/IKKnMh5y8XLlMyS5F9Mt6YM0Y7VGpOyShhM5cznyWTh7tiiw+4bz1SjyAOVbuQwP1+MeWqG32xnI8a4jbDqX7D2dL5+NlX+DCU5hrthXHAd6dl/J2vsyruAcr08jWaUophvEYMN5Zu3NkHa9dGV2tt8LO7RnvmKwCgtJ61dgqu1Ir7BLxSW+eNxcgtndfG9wtL8/WsXXcJ1aigopsPyxRcW67vCbNOSisGEwde1WtpB3Da9JOw8ngRX2RDb8YaHe3xPzozrStMAvGPgte8aR7T14TjzWUfK3u+DwTigj2lxe9j4GM0GSyh0xPlzV7KUZ076KBGkuFqKcyJx0mAww8T8iyDP2kjjzFEzcJdrD8jlkCbh+/EES89LYFW8Ni1LPsZAxDrb0eWBadlDOIZikVDqVMuqEhLX2RWEnuOfkkWuXvTytW/JckvQaCKrji7WNqkBFdkY/kBqQUNkDUORklQ5yX0K/jhZpiLD7CSJ63cu0hUdhN7ZLIlES9DkPPaXcIx/5USVciiSWaX34ExXzZs5pQ8d5M+YMoLI63PCEveZ0q8OX0nq1xHa6VHk5M2FRLmhk1XzC2pFiM9kOArKWbMSKYT2S5zYhiZd8FnknfEOgvPjdQ203qGyaddQsdbAZLax6ts1U8kORZ/fcojZUJCXcWkmw2lk7UEKffYSueJOynbIbi+LkmHSKhLimKj0fjf+QMWoDEuVRT7GAAAAABJRU5ErkJggg=='

  const handleImageChange = async (e) => {
    console.log('Image Change:', e.target.files)
    const file = e.target.files[0]
    setFieldValue('imageFile', file)
    if (file) {
      const image_url = await uploadImage({
          file: file,
          desiredPath: `design/logo/image`,
          toaster: false
      })
      .then((res) => {
          setFieldValue('logoUrl',res.response.data)
          return res.response.data;
      })
      .catch((err) => {
          return null;
      });
    }
  }

  return (
    <>
    <img
                    className='w-[150px] h-[150px] object-contain rounded-full border border-gray-300 shadow-md'
                    src={
                      values.imageFile
                        ? URL.createObjectURL(values.imageFile)
                        : values.logoUrl || logoUrl
                    }
                    alt='Profile'
                  />
                  <button
                    type='button'
                    className='relative overflow-hidden cursor-pointer bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-900'
                  >
                    Upload Logo
                    <input
                      type='file'
                      id='profile_image'
                      name='profile_image'
                      className='absolute inset-0 cursor-pointer opacity-0'
                      onChange={handleImageChange}
                    />
                  </button>
    </>
  )
}

function Settings() {
  const AppService = useAppServices()
  const { company_id, app_id } = useParams()
  const { agency } = useAgency()
  const { brandings, generalInfos,urlGroups,domain } = useTheme()
  console.log(domain,"generalInfos123")
  const [activeSection, setActiveSection] = useState('main')



  const initState = {
    companyname: '',
    countryname: '',
    address: '',
    phone: '',
    website: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    domain: domain,
    image: null,
    poweredby:'',
    branding: { ...brandings },
    generalInfo: { ...generalInfos },
    urlGroups: [...urlGroups],
    logoUrl: agency.logoUrl || '',
    imageFile: '',
  }


  const updateDomain = async (values, { setSubmitting }) => {
    const { response } = await AppService.domain.update({
      payload: {
        domain: values.domain,
        domain_update: true,
      },
    })
    setSubmitting(false)
    if (response) {
      Swal.fire('Domain has been updated successfully')
      return true;

    } else {
      Swal.fire('Failed to update domain')
      return false;

    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log('Form Submitted:', values)
    const payload = {
      app_id: app_id,
      companyId: company_id,
      logoUrl: values.logoUrl,
      branding: {
        pageBackground: values.branding.pageBackground,
        buttonBgColor: values.branding.buttonBgColor,
        buttonHoverColor: values.branding.buttonHoverColor,
        buttonTextColor: values.branding.buttonTextColor,
        textColor: values.branding.textColor,
        textSecondaryColor: values.branding.textSecondaryColor,
        textFieldBgColor: values.branding.textFieldBgColor,
        textFieldTextColor: values.branding.textFieldTextColor,
        textFieldBorderColor: values.branding.textFieldBorderColor,
        cardBgColor: values.branding.cardBgColor,
      },
      urlGroups: values.urlGroups,
      generalInfo: {
        companyName: values.generalInfo.companyName,
        companyAddress: values.generalInfo.companyAddress,
        companyPhone: values.generalInfo.companyPhone,
        companyWebsite: values.generalInfo.companyWebsite,
        poweredBy: values.generalInfo.poweredBy,
    },
  }

  if (initState.domain !== values.domain) {
    const domainUpdated = await updateDomain(values, { setSubmitting });
    if (domainUpdated) 
    {
      payload.domain = values.domain; 

    }
  
  }
  
  const result = await AppService.agency.update_with_company_id({ payload });
  

  }





  const getProfile = async () => {
    const { response } = await AppService.agency.getProfile({
      query: `company_id=${company_id}&app_id=${app_id}`,
    })
    if (response) {
      console.log(response, 'responseresponse')
      // setAgency(response.data)
      // setProfile(response.data?.profile || {})
      // setProfileImage(response.data?.profile?.profile_image || "/assets//images/BlankProfile.jpg")

      // setloader(false)
    } else {
      // setloader(false)
    }
  }
  const onLoad = async () => {
    getProfile()
  }
  useEffect(() => {
    onLoad()
  }, [])

  return (
    <div className='relative min-h-screen'>
      {/* Main Content */}
      <div className='relative z-10 mx-auto py-12 px-6'>
        <Formik initialValues={initState} onSubmit={handleSubmit}>
          {({ values, isSubmitting }) => (
            <Form className='grid grid-cols-1 gap-20 md:grid-cols-3 max-w-5xl mx-auto'>
              {/* Left Section for Image Upload */}
              <div className=''>
                <div className='author_img flex flex-col gap-4 items-center'>
                  <ImageLoader/>
                  <div className='w-full flex flex-col gap-2'>
                    {[
                      { section: 'main', label: 'General Info' },
                      { section: 'branding', label: 'Branding' },
                      { section: 'socialLinks', label: 'URLs' },
                      { section: 'Domain', label: 'Domain Configuration' },
                    ].map(({ section, label }) => (
                      <>
                        <Button
                          active={activeSection === section}
                          className='!my-1'
                          onClick={() => setActiveSection(section)}
                        >
                          <span>{label}</span>
                        </Button>
                      </>
                    ))}
                  </div>
                </div>
              </div>

              {/* Right Section for Form Fields */}
              <div className='col-span-2'>
                {activeSection === 'main' && <GeneraInfo />}
                {activeSection === 'branding' && <Branding />}
                {activeSection === 'socialLinks' && <SocialLinks />}
                {activeSection === 'Domain' && <Domain />}

                <Button
                  processing={isSubmitting}
                  type='submit'
                  className='w-full mt-4'
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default Settings
