import React from 'react'
import Navbar from './Navbar'
import { DocProvider, TABS, useDoc } from './doc.context'
import Doc from './Doc'
import Settings from './Settings/Settings'
import Editor from './Editor/Editor'
import Tabs from './Tabs'
import FAQs from './FAQs/FAQs'
import Footer from './footer'
import { useTheme } from '../../context/theme.context'

const TabComponent = () => {
  const { activeTab } = useDoc()
  const components = {
    [TABS.DOC]: <Doc />,
    [TABS.FAQ]: <FAQs />,
    [TABS.SETTING]: <Settings />,
    [TABS.EDITOR]: <Editor />,
  }
  return components[activeTab]
}

const GitBookLayout = () => {
  const { brandings } = useTheme()

  return (
    <DocProvider>
      <div
        style={{
          backgroundColor: brandings.pageBackground,
          color: brandings.textSecondaryColor,
        }}
        className='h-screen overflow-auto relative'
      >
        <div className='max-w-[1536px] mx-auto px-6'>
          {/* Header */}
          <Navbar />

          {/* Main Content */}
          <Tabs />
          <TabComponent />

          <Footer />
        </div>
      </div>
    </DocProvider>
  )
}

export default GitBookLayout
