/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useContext, useEffect } from 'react'
import { useAgency } from './agency.context'


const defaultdomain = 'https://www.example.com' 
  
const defaultBranding = {
  pageBackground: '#1e1e1e',
  textColor: '#ffffff',
  textSecondaryColor: '#d1d5db',
  buttonBgColor: '#1f2937',
  buttonHoverColor: '#374151',
  buttonTextColor: '#ffffff',
  cardBgColor: '#1f2937',
  textFieldBgColor: '#1f2937',
  textFieldTextColor: '#ffffff',
  textFieldBorderColor: '#374151',
}

const defaultGeneralInfo = {
  companyName: 'KNOWLEDGE BASE',
  companyLogo: 'https://via.placeholder.com/150',
  companyAddress: 'Los Angeles, CA',
  companyPhone: '+1 234 567 890',
  companyWebsite: 'https://www.example.com',
  poweredBy: 'Knowledge Base',
}


const initThemeContextPropsState = {
  domain: defaultdomain,
  setDomain: () => {},
  brandings: defaultBranding,
  generalInfos: defaultGeneralInfo,
  setGeneralInfo: () => {},
  setBrandings: () => {},
  urlGroups: [],
  setUrlGroups: () => {},
  logoUrl: '',
  setLogoUrl: () => {},
  getHoverProps: ({ active }) => {},
}

const ThemeContext = createContext(initThemeContextPropsState)

const useTheme = () => {
  return useContext(ThemeContext)
}

const ThemeProvider = ({ children }: any) => {
  const { agency } = useAgency()

  const [brandings, setBrandings] = useState(
    agency?.branding || defaultBranding
  )

  const [domain, setDomain] = useState(
    agency?.domain || defaultdomain
  )

  console.log(agency,"generalInfos")
  const [generalInfos, setGeneralInfo] = useState(
    agency?.generalInfo || defaultGeneralInfo
  )

  const [urlGroups, setUrlGroups] = useState(
    agency?.urlGroups || [],
  )

  const [logoUrl, setLogoUrl] = useState(
    agency?.logoUrl || '')


  useEffect(() => {
    if (agency?.branding) {
      setBrandings(agency.branding);
    }
    if (agency?.generalInfo) {
      setGeneralInfo(agency.generalInfo);
    }
    if (agency?.urlGroups) {
      setUrlGroups(agency.urlGroups);
    }
    if (agency?.logoUrl) {
      setLogoUrl(agency.logoUrl);
    }

    if (agency?.domain) {
      setDomain(agency.domain);
    }
  }, [agency?.branding, agency?.generalInfo, agency?.urlGroups, agency?.domain, agency?.logoUrl]);
  
  const [loader, setLoader] = useState(false)

  const onLoad = () => {
    console.log('component loaded')
  }
  useEffect(onLoad, [])

  const getHoverProps = ({ active }) => {
    return {
      onMouseEnter: (e) => (e.currentTarget.style.color = brandings.textColor),
      onMouseLeave: (e) =>
        (e.currentTarget.style.color = active
          ? brandings.textColor
          : brandings.textSecondaryColor),
    }
  }

  return (
    <ThemeContext.Provider
      value={{
        domain,
        setDomain,
        brandings,
        setBrandings,
        generalInfos,
        setGeneralInfo,
        urlGroups,
        setUrlGroups,
        logoUrl,
        setLogoUrl,
        getHoverProps,
      }}
    >
      {loader ? (
        <div className='w-full h-screen flex justify-center items-center flex-col bg-[#1E1E1E] text-gray-200'>
          <div>Loading...</div>
        </div>
      ) : (
        children
      )}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, useTheme }
