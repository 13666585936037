import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppServices } from '../../../hook/services'
import { useFAQ } from './faq.context'
import { useDoc } from '../doc.context'
import { useTheme } from '../../../context/theme.context'
import Button from '../comp/Button'
import { SearchIcon } from '../Navbar'
import { Spinner } from '../Doc'
import Input from '../comp/Input'
import { Form, Formik } from 'formik'
import Swal from 'sweetalert2'

function ArrowIcon({
  isExpanded,
  size = 12,
}: {
  isExpanded: boolean,
  size?: number,
}) {
  return (
    <svg
      viewBox='0 0 1024 1024'
      width={size}
      height={size}
      className={`transition-transform duration-150 ${
        isExpanded ? 'rotate-[270deg]' : 'rotate-180'
      }`}
      fill='currentColor'
    >
      <path
        d='M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z'
        fill='currentColor'
      ></path>
    </svg>
  )
}

function NavItem({
  title,
  children,
  active,
  disabled,
  expanded = false,
  cat,
  faq,
}: {
  title: string,
  active?: boolean,
  disabled?: boolean,
  expanded?: boolean,
  faq?: object,
  cat?: object,
  children?: React.ReactNode,
}) {
  const { brandings, getHoverProps } = useTheme()
  const { setActiveFaq, setActiveCat } = useFAQ()
  const [isExpanded, setIsExpanded] = useState(false)

  const handleSelectedDoc = () => {
    if (disabled) return
    if (children) {
      if (cat) setActiveCat(cat)
      return setIsExpanded(!isExpanded)
    }

    setActiveFaq(faq)
    setActiveCat(cat)
  }

  useEffect(() => {
    if (expanded) {
      setIsExpanded(expanded)
    }
  }, [expanded])

  return (
    <li className='text-sm'>
      <div
        style={{
          color: active ? brandings.textColor : brandings.textSecondaryColor,
        }}
        {...getHoverProps({ active })}
        className={`flex items-center justify-between p-2 ${
          active ? 'bg-gray-800' : ''
        } ${
          disabled
            ? 'opacity-50 cursor-default'
            : 'hover:bg-gray-800 hover:rounded-md cursor-pointer'
        }`}
        onClick={handleSelectedDoc}
      >
        <span>{title}</span>
        {children && (
          <div className=''>
            <ArrowIcon isExpanded={isExpanded} />
          </div>
        )}
      </div>
      {isExpanded && children && (
        <ul className='pl-2 border-l border-gray-600'>{children}</ul>
      )}
    </li>
  )
}

function DocList({ category }: any) {
  const { faqs, activeFaq } = useFAQ()

  return faqs
    ?.filter((faq) => faq.category._id == category._id)
    ?.map((faq) => (
      <NavItem
        key={faq._id}
        title={faq.question}
        faq={faq}
        cat={category}
        active={activeFaq._id == faq._id}
      />
    ))
}

function Modal({ open, onClose }: any) {
  const { brandings } = useTheme()
  const { company_id } = useParams()
  const AppService = useAppServices()
  const { faqs, setFaqs } = useFAQ()

  const initState = {
    question: '',
    answer: '',
    category: '',
  }

  const createFAQ = async (payload) => {
    const { response } = await AppService.k_faq.create({ payload })
    if (response) {
      faqs.unshift(response.data)
      setFaqs([...faqs])
    }
    return
  }

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const payload = {
      name: values.category,
      companyId: company_id,
      type: 'agency',
    }

    const { response } = await AppService.k_faq_category.create({ payload })
    if (response) {
      await createFAQ({
        question: values.question,
        answer: values.answer,
        category: response.data,
        type: 'agency',
      })
      Swal.fire('Faq successfully added')
    }
    setSubmitting(false)
    onClose()
  }

  return open ? (
    <div
      className='fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 backdrop-blur-lg z-50'
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose()
        }
      }}
    >
      <div className='bg-gray-800 p-8 rounded-xl shadow-xl w-[80%] max-w-3xl h-[80%] overflow-auto max-h-fit relative'>
        {/* Input Field with Search Icon */}
        <div
          style={{ color: brandings.textColor }}
          className='text-2xl font-bold'
        >
          Add FAQ Category
        </div>

        <Formik initialValues={{ ...initState }} onSubmit={handleSubmit}>
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className='relative'>
              <div className='mt-4'>
                <div>Categroy Name</div>
                <Input
                  placeholder='Enter your category name'
                  onChange={(e) => setFieldValue('category', e.target.value)}
                  value={values.category}
                />
              </div>
              <div className='mt-4'>
                <div>Question</div>
                <Input
                  placeholder='Enter your question'
                  onChange={(e) => setFieldValue('question', e.target.value)}
                  value={values.question}
                />
              </div>
              <div className='mt-4'>
                <div>Answer</div>
                <textarea
                  placeholder='Answer'
                  className='w-full px-2.5 py-1.5 rounded-sm bg-[#2c2c2c] placeholder:text-[#aaaaaa] hover:!border-[#6a6a6a] transition-all ease-in'
                  style={{
                    border: '1px solid #4a4a4a',
                    color: brandings.textColor,
                  }}
                  onChange={(e) => setFieldValue('answer', e.target.value)}
                  value={values.answer}
                />
              </div>
              <div className='w-fit ml-auto'>
                <Button
                  type='submit'
                  processing={isSubmitting}
                  className='mt-4'
                >
                  Add Category
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  ) : (
    ''
  )
}

function Sidebar() {
  const { brandings } = useTheme()
  const { categories, activeCat } = useFAQ()
  const [openModal, setOpenModal] = useState(false)

  return (
    <aside className='w-64 p-6 pt-0 h-[calc(100vh-390px)] min-h-96 overflow-auto custom-scrollbar'>
      <div
        style={{ backgroundColor: brandings.pageBackground }}
        className='sticky top-0 pt-6 z-10'
      >
        <Button onClick={() => setOpenModal(true)}>{'Add New Category'}</Button>
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)} />
      {categories?.map((category) => (
        <>
          <ul className='space-y-2 mb-8 '>
            <NavItem
              key={category._id}
              title={category.name}
              active={category._id === activeCat._id}
              expanded={category._id === activeCat._id}
              cat={category}
            >
              <DocList category={category} />
            </NavItem>
          </ul>
        </>
      ))}
    </aside>
  )
}

export default Sidebar
