import React from 'react'
import { useTheme } from '../../context/theme.context'

const Footer = () => {

  const { brandings, generalInfos, urlGroups,logoUrl } = useTheme()
  const Links = [
    
    {
      title: 'Company',
      links: [
        { name: generalInfos.companyAddress, url: '#' },
        { name: generalInfos.companyPhone, url: '#' },
        { name: generalInfos.companyWebsite, url: '#' },
      ],    },
   
  ]

  urlGroups.forEach(group => {
    Links.push({
      title: group.groupName,
      links: group.links.map((link) => ({ name: link.name, url: link.url })) // Extract only the names of the links
    });
  });
 

  return (
    <footer className='py-10 border-t border-gray-700'>
      <div className='px-4 flex justify-between items-start'>
        {/* Logo Section */}
        <div className='w-1/5'>
          <div className='flex items-center space-x-3'>
           
             <img src={logoUrl} alt='logo' className='w-10 h-10' />
            <span className='text-xl font-semibold'>{generalInfos.companyName}</span>
          </div>
        </div>

        {/* Links Section */}
        <div className='w-4/5 flex justify-between text-gray-500'>
          {Links.map((section, index) => (
            <div key={index} className='w-1/4'>
              <h4 className='text-xs uppercase font-semibold mb-3 text-white'>
                {section.title}
              </h4>
              <ul>
              {section.links.map((link, idx) => {

if (!link.url) {
  return null; // Or render an error message
}
let formattedUrl = link.url; 

if (link.title !== 'Company' && !formattedUrl.startsWith("http")) {
  formattedUrl = `https://${formattedUrl}`;
}

  return (
    <li key={idx}>
      <a
        href={formattedUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-500 text-sm hover:text-white transition-colors"
      >
        {link.name}
      </a>
    </li>
  );
})}

              </ul>
            </div>
          ))}
        </div>

        {/* Icons Section */}
        {/* <div className='flex items-center justify-end space-x-2'>
          {[
            {
              label: 'Light Mode',
              icon: (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18px'
                  height='18px'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M11 1C11 0.447715 11.4477 0 12 0C12.5523 0 13 0.447715 13 1V3C13 3.55228 12.5523 4 12 4C11.4477 4 11 3.55228 11 3V1Z'
                    fill='#ffff'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12ZM8.06167 12C8.06167 14.1751 9.82492 15.9383 12 15.9383C14.1751 15.9383 15.9383 14.1751 15.9383 12C15.9383 9.82492 14.1751 8.06167 12 8.06167C9.82492 8.06167 8.06167 9.82492 8.06167 12Z'
                    fill='#ffff'
                  />
                  <path
                    d='M20.4853 3.51472C20.0947 3.12419 19.4616 3.12419 19.0711 3.51472L17.6568 4.92893C17.2663 5.31946 17.2663 5.95262 17.6568 6.34315C18.0474 6.73367 18.6805 6.73367 19.0711 6.34315L20.4853 4.92893C20.8758 4.53841 20.8758 3.90524 20.4853 3.51472Z'
                    fill='#ffff'
                  />
                  <path
                    d='M1 13C0.447715 13 0 12.5523 0 12C0 11.4477 0.447715 11 1 11H3C3.55228 11 4 11.4477 4 12C4 12.5523 3.55228 13 3 13H1Z'
                    fill='#ffff'
                  />
                  <path
                    d='M3.51472 3.51472C3.1242 3.90524 3.1242 4.53841 3.51472 4.92893L4.92894 6.34315C5.31946 6.73367 5.95263 6.73367 6.34315 6.34315C6.73368 5.95262 6.73368 5.31946 6.34315 4.92893L4.92894 3.51472C4.53841 3.12419 3.90525 3.12419 3.51472 3.51472Z'
                    fill='#ffff'
                  />
                  <path
                    d='M11 21C11 20.4477 11.4477 20 12 20C12.5523 20 13 20.4477 13 21V23C13 23.5523 12.5523 24 12 24C11.4477 24 11 23.5523 11 23V21Z'
                    fill='#ffff'
                  />
                  <path
                    d='M6.34315 17.6569C5.95263 17.2663 5.31946 17.2663 4.92894 17.6569L3.51473 19.0711C3.1242 19.4616 3.1242 20.0948 3.51473 20.4853C3.90525 20.8758 4.53842 20.8758 4.92894 20.4853L6.34315 19.0711C6.73368 18.6805 6.73368 18.0474 6.34315 17.6569Z'
                    fill='#ffff'
                  />
                  <path
                    d='M21 13C20.4477 13 20 12.5523 20 12C20 11.4477 20.4477 11 21 11H23C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H21Z'
                    fill='#ffff'
                  />
                  <path
                    d='M17.6568 17.6569C17.2663 18.0474 17.2663 18.6805 17.6568 19.0711L19.0711 20.4853C19.4616 20.8758 20.0947 20.8758 20.4853 20.4853C20.8758 20.0948 20.8758 19.4616 20.4853 19.0711L19.0711 17.6569C18.6805 17.2663 18.0474 17.2663 17.6568 17.6569Z'
                    fill='#ffff'
                  />
                </svg>
              ),
            },
            {
              label: 'System Mode',
              icon: (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18px'
                  height='18px'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M3 7C3 5.11438 3 4.17157 3.58579 3.58579C4.17157 3 5.11438 3 7 3H12H17C18.8856 3 19.8284 3 20.4142 3.58579C21 4.17157 21 5.11438 21 7V10V13C21 14.8856 21 15.8284 20.4142 16.4142C19.8284 17 18.8856 17 17 17H12H7C5.11438 17 4.17157 17 3.58579 16.4142C3 15.8284 3 14.8856 3 13V10V7Z'
                    stroke='#323232'
                    strokeWidth='2'
                    strokeLinejoin='round'
                    fill='#ffff'
                  />
                  <path
                    d='M13.3333 17L14.6667 19V19C15.2366 19.8549 14.6238 21 13.5963 21H12H10.4037C9.37624 21 8.7634 19.8549 9.33333 19V19L10.6667 17'
                    stroke='#323232'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    fill='#ffff'
                  />
                  <path
                    d='M3 13H21'
                    stroke='#323232'
                    strokeWidth='2'
                    strokeLinecap='round'
                    fill='#ffff'
                  />
                </svg>
              ),
            },
            {
              label: 'Dark Mode',
              icon: (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18px'
                  height='18px'
                  viewBox='0 0 24 24'
                  fill='#ffff'
                >
                  <path
                    d='M2.02911 12.42C2.38911 17.57 6.75911 21.76 11.9891 21.99C15.6791 22.15 18.9791 20.43 20.9591 17.72C21.7791 16.61 21.3391 15.87 19.9691 16.12C19.2991 16.24 18.6091 16.29 17.8891 16.26C12.9991 16.06 8.99911 11.97 8.97911 7.13996C8.96911 5.83996 9.23911 4.60996 9.72911 3.48996C10.2691 2.24996 9.61911 1.65996 8.36911 2.18996C4.40911 3.85996 1.69911 7.84996 2.02911 12.42Z'
                    stroke='#292D32'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              ),
            },
          ].map((button, index) => (
            <button
              key={index}
              className='p-2 -mt-2 rounded hover:bg-gray-700 transition-colors'
              aria-label={button.label}
            >
              {button.icon}
            </button>
          ))}
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
