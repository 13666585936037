import React from 'react'
import Docs from './components/Docs'
import Navbar from '../../components/Navbar'
import Banner from '../../components/Banner'
import GitBookLayout from './Gitbook'
import { ThemeProvider } from '../../context/theme.context'
import { AgencyProvider } from '../../context/agency.context'

const index = () => {
  return (
    <>
      <GitBookLayout />

      {/* <div className='body_wrapper'>
        <Navbar />
        <Banner />
        <Docs />
      </div> */}
    </>
  )
}

export default index
