import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAppServices } from '../../hook/services'
import { useLanguageInfo } from '../../context/language'
import { useDoc } from './doc.context'
import { Spinner } from './Doc'
import { useTheme } from '../../context/theme.context'
import Button from './comp/Button'
import Input from './comp/Input'

export function SearchIcon() {
  return (
    <svg
      className='w-5 h-5 text-gray-400 mr-2'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M21 21l-6-6m2-4a7 7 0 11-14 0 7 7 0 0114 0z'
      />
    </svg>
  )
}

function NavItem({ label = '', url }: any) {
  const { brandings, getHoverProps } = useTheme()
  const active = window.location.pathname.includes(url.split('/').pop())
  return (
    <Link
      to={url}
      style={{
        color: active ? brandings.textColor : brandings.textSecondaryColor,
      }}
      {...getHoverProps({ active })}
      className={`text-sm transition-all ease-in`}
    >
      {label}
    </Link>
  )
}

function SearchButton() {
  const { brandings } = useTheme()
  const { data, setActiveCat, setActiveDoc } = useDoc()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [docs, setDocs] = useState([])
  const AppService = useAppServices()
  const { company_id, app_id } = useParams()
  const [searching, setSearching] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const getDocs = async (query) => {
    setSearching(true)
    const { response } = await AppService.docs.query_doc({
      query: `query=${query}&company_id=${company_id}&app_id=${app_id}`,
    })
    if (response) {
      console.log(response.data, 'responsedocs')
      setDocs(response.data)
    }
  }

  const searchDoc = async (e) => {
    const query = e.target.value
    await getDocs(query)

  }

  useEffect(() => {
    if (isModalOpen) searchDoc({ target: { value: '' } })
  }, [isModalOpen])

  const handleSelectDoc = (doc) => {
    console.log(data, doc)
    const category = data?.category.find(
      (category) => category._id == doc.category
    )
    console.log(category)
    setActiveCat(category)
    setActiveDoc(doc)
    // const sub_category = data?.sub_category.find(
    //   (sub_category) => sub_category._id == doc.sub_category
    // )
    // console.log(sub_category)
    setIsModalOpen(false)
  }

  return (
    <div className='inline-block'>
      {/* Search Button */}
      <form className='items-center inline-flex'>
        <label htmlFor='voice-search' className='sr-only'>
          Search
        </label>
        <div className='relative w-full'>
          <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
            <SearchIcon />
          </div>
          <Input
            type='text'
            id='voice-search'
            style={{ color: brandings.textColor }}
            className=' w-full ps-10 p-2.5 py-2'
            placeholder='Ask or Search...'
            required
            onClick={openModal} // Open modal on click of search bar
          />
        </div>
      </form>

      {/* Modal */}
      {isModalOpen && (
        <div
          className='fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 backdrop-blur-lg z-50'
          onClick={(e) => {
            // Close modal if clicking outside of the modal content
            if (e.target === e.currentTarget) {
              closeModal()
            }
          }}
        >
          <div className='p-8 rounded-xl shadow-xl w-[80%] max-w-3xl h-[80%]  max-h-fit relative' style={{ backgroundColor: brandings.cardBgColor, opacity: 0.75 }}>
            {/* Input Field with Search Icon */}
            <div className='relative'>
              <input
                type='text'
                id='modal-search'
                style={{ color: brandings.textColor }}
                className='bg-gray-700 border border-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pl-10 placeholder-gray-400'
                required
                onChange={searchDoc}
                autoFocus
              />
              <div className='absolute inset-y-0 left-4 flex items-center pointer-events-none'>
                <SearchIcon />
              </div>
            </div>

            {/* Search Results List */}
            {!searching ? (
              <div className='flex justify-center items-center h-32'>
                <Spinner />
              </div>
            ) : (
              <ul className='mt-4 h-[inherit] overflow-y-auto space-y-2 text-sm text-gray-300'>
                {docs.map((doc) => (
                  <li
                    key={doc._id}
                    onClick={() => handleSelectDoc(doc)}
                    className='p-2 hover:bg-gray-700 rounded-lg cursor-pointer flex items-center'
                  >
                    <SearchIcon />
                    {doc.title}
                  </li>
                ))}
                {/* More list items */}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

function DropdoownItem({ name, _id, setOpen }: any) {
  const { brandings } = useTheme()
  const [language, handleLanguage] = useLanguageInfo()

  const handleSelect = () => {
    handleLanguage({ target: { value: _id } })
    setOpen(false)
  }

  return (
    <div
      onClick={handleSelect}
      style={{ color: brandings.textColor }}
      className={`block px-4 py-2 text-sm hover:bg-gray-800 ${
        language == _id ? 'bg-gray-800' : ''
      }`}
    >
      {name}
    </div>
  )
}

function Dropdoown() {
  const { brandings } = useTheme()
  const [open, setOpen] = React.useState(false)
  const [language] = useLanguageInfo()
  const [languages, setLanguages] = useState([])
  const AppService = useAppServices()

  const getDocsLanguages = async () => {
    const { response } = await AppService.docs.getlanguages()
    if (response) {
      setLanguages(response.data)
    }
  }
  useEffect(() => {
    getDocsLanguages()
  }, [])

  return (
    <div className='relative inline-block text-left'>
      <div>
        <Button
          className='inline-flex w-full'
          onClick={() => setOpen(!open)}
        >
          {languages.find((lang) => lang._id == language)?.name || 'English'}
          <svg
            className={`-mr-1 ml-1 size-5  transition-transform duration-150 ${
              open ? 'transform rotate-180' : ''
            }`}
            viewBox='0 0 20 20'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z'
              clipRule='evenodd'
            />
          </svg>
        </Button>
      </div>

      {open ? (
        <div style={{ backgroundColor: brandings.cardBgColor }} className='absolute transition-all duration-150 right-0 z-10 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none'>
          <div className='py-1'>
            <DropdoownItem _id='english' name='English' setOpen={setOpen} />
            {languages.map((language) => (
              <DropdoownItem
                key={language._id}
                {...language}
                setOpen={setOpen}
              />
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

function Navbar() {
  const { company_id, app_id } = useParams()
  const { brandings, generalInfos,logoUrl } = useTheme()

  const navoptions = [
    { label: 'Documents', url: `/${app_id}/${company_id}/docs`, active: false },
    // { label: 'FAQs', url: `/${app_id}/${company_id}/faqs`, active: true },
    // {
    //   label: 'Profile',
    //   url: `/${app_id}/${company_id}/profile`,
    //   active: false,
    // },
  ]

  return (
    <div>
      <header className='px-4 pt-4 flex justify-between items-center mb-4'>
      <div className='flex items-center space-x-3'>
           
           <img src={logoUrl} alt='logo' className='w-10 h-10' />
          <span className='text-xl font-semibold'>{generalInfos.companyName}</span>
        </div>
        <nav className='space-x-6'>
          {navoptions.map((navoption) => (
            <NavItem key={navoption.label} {...navoption} />
          ))}
          <Dropdoown />
          <SearchButton />
        </nav>
      </header>
    </div>
  )
}

export default Navbar
