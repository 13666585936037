import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { useDoc } from '../doc.context'
import { FAQProvider, useFAQ } from './faq.context'
import { useAppServices } from '../../../hook/services'
import { Form, Formik } from 'formik'
import Swal from 'sweetalert2'
import { Tooltip } from 'react-tooltip'
import { Spinner } from '../Doc'
import { useTheme } from '../../../context/theme.context'
import Input from '../comp/Input'
import Button from '../comp/Button'

function FAQView() {
  const { brandings } = useTheme()
  const {
    activeCat,
    activeFaq,
    faqs,
    setFaqs,
    categories,
    setCategories,
    resetFAQ,
  } = useFAQ()
  const [addNewFAQ, setAddNewFAQ] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const AppService = useAppServices()

  const initState = {
    question: '',
    answer: '',
  }

  if (!addNewFAQ) {
    const targetElement = document.getElementById(activeFaq?._id)
    targetElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  } else {
    const form = document.getElementById('new-faq')
    form?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  useEffect(() => {
    const targetElement = document.getElementById(activeFaq?._id)
    targetElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [activeFaq])

  const handleSubmit = async (form, { setSubmitting }) => {
    const payload = {
      ...form,
      category: activeCat,
      type: 'agency',
    }

    const { response } = await AppService.k_faq.create({ payload })
    if (response) {
      setAddNewFAQ(false)
      faqs.unshift(response.data)
      setFaqs([...faqs])
      Swal.fire('Faq successfully added')
    }
    setSubmitting(false)
  }

  const handleDeleteFAQ = async (faqId) => {
    setDeleting(true)
    const { response } = await AppService.k_faq.delete({
      query: `_id=${faqId}`,
    })
    setDeleting(false)
    if (response) {
      const index = faqs.findIndex((faq) => faq._id === faqId)
      faqs.splice(index, 1)
      setFaqs([...faqs])
      Swal.fire('Faq successfully deleted')
    }
  }

  const deleteCategory = async () => {
    setDeleting(true)
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this faq!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    })
    if (isConfirmed) {
      const { response } = await AppService.k_faq_category.delete({
        query: `_id=${activeCat._id}`,
      })
      setDeleting(false)
      if (response) {
        const index = categories.findIndex((cat) => cat._id === activeCat._id)
        categories.splice(index, 1)
        setCategories([...categories])
        Swal.fire('Category successfully deleted')
        resetFAQ()
      }
    } else return
  }

  return (
    <main className='flex-1 p-6 pt-0 h-[calc(100vh-390px)] min-h-96 overflow-auto scrollbar-hidden'>
      <div
        style={{ backgroundColor: brandings.pageBackground }}
        className='flex items-center gap-4 justify-between py-4 pt-8 sticky top-0'
      >
        <h1
          style={{ color: brandings.textColor }}
          className='text-xs font-bold m-0'
        >
          {activeCat.name || ''}
        </h1>
        <div className='flex items-center gap-4'>
          {activeCat.type == 'agency' ? (
            <div>
              <div
                data-tooltip-id='delete'
                data-tooltip-content='Delete category'
                className='cursor-pointer'
                onClick={deleteCategory}
              >
                <svg
                  fill='currentColor'
                  version='1.1'
                  id='Capa_1'
                  width={'20px'}
                  height={'20px'}
                  viewBox='0 0 482.428 482.429'
                >
                  <g>
                    <g>
                      <path d='M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098    c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117    h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828    C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879    C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096    c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266    c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979    V115.744z' />
                      <path d='M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z' />
                      <path d='M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z' />
                      <path d='M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07    c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z' />
                    </g>
                  </g>
                </svg>
                <Tooltip id='delete' />
              </div>
            </div>
          ) : (
            ''
          )}
          <Button
            className='flex items-center gap-3 !w-fit !mb-0'
            onClick={() => setAddNewFAQ(true)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-4 h-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M16.862 4.487l1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10'
              />
            </svg>
            Add New Q/A
          </Button>
        </div>
      </div>
      <div id='new-faq'>
        {addNewFAQ ? (
          <Formik initialValues={{ ...initState }} onSubmit={handleSubmit}>
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <div className={`border-b mb-6 pb-6`}>
                  <div
                    onClick={() => setAddNewFAQ(false)}
                    className='bg-gray-700 hover:bg-gray-800 w-6 h-6 rounded-full flex items-center justify-center float-right cursor-pointer transition-all ease-in hover:scale-110'
                  >
                    x
                  </div>
                  <h1
                    style={{ color: brandings.textColor }}
                    className='text-2xl font-bold'
                  >
                    New Question
                  </h1>
                  <Input
                    placeholder='Question'
                    onChange={(e) => setFieldValue('question', e.target.value)}
                    value={values.question}
                  />
                  <textarea
                    placeholder='Answer'
                    className='w-full mt-4 px-2.5 py-1.5 rounded-sm bg-[#2c2c2c] placeholder:text-[#aaaaaa] hover:!border-[#6a6a6a] transition-all ease-in'
                    style={{
                      border: '1px solid #4a4a4a',
                      color: brandings.textColor,
                    }}
                    onChange={(e) => setFieldValue('answer', e.target.value)}
                    value={values.answer}
                  />
                  <div className='w-fit ml-auto'>
                    <Button type='submit' disabled={isSubmitting}>
                      {isSubmitting ? 'Loading... ' : 'Add'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          ''
        )}
      </div>
      {faqs
        .filter((faq) => faq.category._id === activeCat._id)
        .map((faq, index) => (
          <div
            id={faq._id}
            key={faq._id}
            className={`border-b ${index == 0 ? '' : 'my-6'} pb-6 ${
              faq._id === activeFaq?._id ? 'animate-[bounce_1s_ease_1]' : ''
            }`}
          >
            <div className='flex items-center justify-between'>
              <h1
                style={{ color: brandings.textColor }}
                className='text-2xl font-bold'
              >
                Q: {faq.question}
              </h1>
              {faq.type == 'agency' ? (
                deleting ? (
                  <Spinner />
                ) : (
                  <div
                    data-tooltip-id='delete'
                    data-tooltip-content='Delete document'
                    className='cursor-pointer'
                    onClick={() => handleDeleteFAQ(faq._id)}
                  >
                    <svg
                      fill='currentColor'
                      version='1.1'
                      id='Capa_1'
                      width={'20px'}
                      height={'20px'}
                      viewBox='0 0 482.428 482.429'
                    >
                      <g>
                        <g>
                          <path d='M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098    c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117    h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828    C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879    C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096    c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266    c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979    V115.744z' />
                          <path d='M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z' />
                          <path d='M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z' />
                          <path d='M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07    c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z' />
                        </g>
                      </g>
                    </svg>
                    <Tooltip id='delete' />
                  </div>
                )
              ) : (
                ''
              )}
            </div>
            <div>{faq.answer}</div>
          </div>
        ))}
    </main>
  )
}

function FAQs() {
  return (
    <FAQProvider>
      <div className='flex px-4'>
        {/* Sidebar */}
        <Sidebar />
        {/* Main Section */}
        <FAQView />
      </div>
    </FAQProvider>
  )
}

export default FAQs
